@import "./style-functions.scss";

.products-similar {
  display: none;
  visibility: hidden;
  opacity: 0;
}

header nav .nav-logotype {
  position: relative;
  z-index: 20;
}
header nav .nav-logotype img {
  width: 100%;
  max-width: 180px;
  min-width: 110px;
  margin-top: -20px;
  @include transition(transform ease-in-out 0.2s);

  &:hover {
    transform: scale(.9);
  }
}
header nav .nav-menu > ul > li:hover {
  color: #456695;
  border-bottom: solid 1px #456695;
}

footer .footer-infos {
  border-radius: 20px;

  @include custom(1366) {
    border-radius: 0;
  }
}
footer .footer-links .socials-medias svg {
  @include transition(transform ease-in-out 0.2s);

  &:hover {
   transform: scale(.9);
  }
}


span.orel-label {
  font-family: "kaushan";
  @include font-size(24px);
}

.spacer {
  width: 100%;
  height: 50px;
}

.main-contents {
  margin-bottom: 50px;

  .layout-2cols {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 50px;
    -moz-column-gap: 50px;
    column-gap: 50px;
    max-width: 650px;
    margin: 30px auto;

    @include md {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
    }

    img {
      display: block;
      margin: 0 auto;
    }
  }

  img.cover {
    width: 100%;
    max-width: 650px;
    margin: 50px auto;
    display: block;

    @include md {
      margin: 15px auto;
    }
  }

  .img-center {
    text-align: center;
    margin: 80px auto;
    max-width: 650px;
    padding: 0 15px;


    @include md {
      margin: 15px auto;

      img {
        width: 80%;
      }
    }

  }

  p {
    font-weight: 300;
  }

  p.text {
    max-width: 650px;
    margin: 0 auto 25px;
    padding: 0 25px;
    @include font-size(16px);
    line-height: 28px;
    letter-spacing: 1px;
    text-align: left;
  }

  p.citation {
    @include font-size(20px);
    font-style: italic;
    max-width: 500px;
    margin: 50px auto;
    padding: 0 25px;
    letter-spacing: 3px;
    line-height: 32px;
    text-align: center;

    @include md {
      margin: 0 auto 20px auto;
    }

    span {
      font-style: normal;
    }
    span.sign {
      display: block;
      @include font-size(14px);
      text-align: right;
      margin: 20px 50px 0;
      letter-spacing: 0;
    }
  }
}

.catalog {

  p {
    font-weight: 300;
  }

  p.text {
    margin: 0 auto 25px;
    padding: 0 25px;
    @include font-size(16px);
    line-height: 28px;
    letter-spacing: 1px;
    text-align: left;
  }

  p.citation {
    @include font-size(20px);
    font-style: italic;
    max-width: 500px;
    margin: 50px auto;
    padding: 0 25px;
    letter-spacing: 3px;
    line-height: 32px;
    text-align: center;

    @include md {
      margin: 0 auto 20px auto;
    }

    span {
      font-style: normal;
    }
    span.sign {
      display: block;
      @include font-size(14px);
      text-align: right;
      margin: 20px 50px 0;
      letter-spacing: 0;
    }
  }
}

.layout-column-right .summary {

  .submit {
    background-color: #FEBE59 !important;
    color: #182433 !important;
  }
  .promo-code input {
    background-color: #263752 !important;
  }
}
