@import "./styles/client-overwrite.scss";

/* Template Configuration */

/* Variables */
$color-base: #FEBE59;
$color-secondary: #182433;
$color-highlight: #FEBE59;
$color-highlight-light: $color-secondary;

$bag-svg-cross-color: $color-secondary;
$bag-svg-ellipse-color: $color-secondary;

// Template
$body-background-color: #101823;

$header-nav-background-color: $body-background-color;
$header-nav-submenu-background-color: $color-secondary;
$header-shopping-count-color-text: $color-secondary;
$header-top-header-background: $color-highlight;
$header-top-header-color-text: white;

/*$input-background-color-focus: $body-background-color;
$input-background-color-mobile: $color-highlight-light;
$input-background-color-focus-mobile: white;
$input-border-color-mobile: white;*/

$input-color-background: $color-secondary;
$input-color-text: #FEBE59;
$input-color-border: $color-secondary;
$input-color-border-onfocus: #FEBE59;
$input-color-background-onfocus: $body-background-color;
$input-color-text-placeholder: #FEBE59;

$input-color-background-mobile: $color-secondary;
$input-color-background-onfocus-mobile: $body-background-color;
$input-color-border-mobile: $color-secondary;

$catalog-menu-color-background: $body-background-color;
$catalog-menu-color-separator: $body-background-color;
$catalog-menu-active-color-background: $color-secondary;
$catalog-menu-active-color-text: #FEBE59;

$menu-mobile-background-color: $body-background-color;
$menu-mobile-text-color: #FEBE59;
$menu-mobile-text-background-color: $body-background-color;
$menu-mobile-text-background-color-hover: $color-secondary;

$submenu-color-background-onhover: $color-secondary;
$submenu-active-color-text: $color-secondary;

$button-color-text-onhover: $color-secondary;
$button-color-span-onhover: $color-secondary;

$form-bullet-title-lock-color: $body-background-color;

$contact-button-submit-color-background: $color-secondary;
$contact-button-submit-color-background-hover: #FEBE59;
$contact-button-submit-color-text: #FEBE59;
$contact-button-submit-color-text-hover: $color-secondary;

$shopping-summary-button-continue-color-background: #263752;
$shopping-summary-button-continue-color-background-hover: #FEBE59;
$shopping-summary-button-continue-color-text: #FEBE59;
$shopping-summary-button-continue-color-text-hover: $color-secondary;

$global-button-color-background: $color-secondary;
$global-button-color-background-hover: #FEBE59;
$global-button-color-text: #FEBE59;
$global-button-color-text-hover: $color-secondary;
$global-button-color-text-span: #FEBE59;
$global-button-color-text-span-hover: $color-secondary;

// Modal
$modal-color-background: $body-background-color;
$modal-close-button-color: $color-secondary;
$modal-close-button-color-hover: #FEBE59;

$modal-button-color-background: $color-secondary;
$modal-button-color-text: #FEBE59;
$modal-button-color-border: $color-secondary;
$modal-button-color-background-hover: $color-secondary;
$modal-button-color-text-hover: #FEBE59;
$modal-button-color-border-hover: #FEBE59;

$modal-button-highlight-color-background: $color-highlight;
$modal-button-highlight-color-text: $color-secondary;
$modal-button-highlight-color-border: $color-highlight;
$modal-button-highlight-color-background-hover: $color-secondary;
$modal-button-highlight-color-text-hover: #FEBE59;
$modal-button-highlight-color-border-hover: #FEBE59;

$modal-answer-text-color: $color-secondary;

// Comment Star
$color-comment-star-no: #dad9e8;
$color-comment-star-yes: #ffc700;

// Logotype
$logotype-min-size: 110px;
$logotype-max-size: 110px;
$logotype-footer-max-size: 120px;

// Cover images
$catalog-cover-img-title-color: white;
$catalog-cover-img-subtitle-color: white;


/* Fonts embedded */
/* BEGIN Thin */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Thin/Roboto-Thin.woff2?v=2.137") format("woff2"), url("../fonts/Thin/Roboto-Thin.woff?v=2.137") format("woff");
  font-weight: 100;
  font-style: normal; }
/* BEGIN Thin Italic */
@font-face {
  font-family: Roboto;
  src: url("../fonts/ThinItalic/Roboto-ThinItalic.woff2?v=2.137") format("woff2"), url("../fonts/ThinItalic/Roboto-ThinItalic.woff?v=2.137") format("woff");
  font-weight: 100;
  font-style: italic; }
/* BEGIN Light */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Light/Roboto-Light.woff2?v=2.137") format("woff2"), url("../fonts/Light/Roboto-Light.woff?v=2.137") format("woff");
  font-weight: 300;
  font-style: normal; }
/* BEGIN Light Italic */
@font-face {
  font-family: Roboto;
  src: url("../fonts/LightItalic/Roboto-LightItalic.woff2?v=2.137") format("woff2"), url("../fonts/LightItalic/Roboto-LightItalic.woff?v=2.137") format("woff");
  font-weight: 300;
  font-style: italic; }
/* BEGIN Regular */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Regular/Roboto-Regular.woff2?v=2.137") format("woff2"), url("../fonts/Regular/Roboto-Regular.woff?v=2.137") format("woff");
  font-weight: 400;
  font-style: normal; }
@font-face {
  font-family: Roboto;
  src: url("../fonts/Regular/Roboto-Regular.woff2?v=2.137") format("woff2"), url("../fonts/Regular/Roboto-Regular.woff?v=2.137") format("woff");
  font-weight: normal;
  font-style: normal; }
/* BEGIN Italic */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Italic/Roboto-Italic.woff2?v=2.137") format("woff2"), url("../fonts/Italic/Roboto-Italic.woff?v=2.137") format("woff");
  font-weight: 400;
  font-style: italic; }
@font-face {
  font-family: Roboto;
  src: url("../fonts/Italic/Roboto-Italic.woff2?v=2.137") format("woff2"), url("../fonts/Italic/Roboto-Italic.woff?v=2.137") format("woff");
  font-weight: normal;
  font-style: italic; }
/* BEGIN Medium */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Medium/Roboto-Medium.woff2?v=2.137") format("woff2"), url("../fonts/Medium/Roboto-Medium.woff?v=2.137") format("woff");
  font-weight: 500;
  font-style: normal; }
/* BEGIN Medium Italic */
@font-face {
  font-family: Roboto;
  src: url("../fonts/MediumItalic/Roboto-MediumItalic.woff2?v=2.137") format("woff2"), url("../fonts/MediumItalic/Roboto-MediumItalic.woff?v=2.137") format("woff");
  font-weight: 500;
  font-style: italic; }
/* BEGIN Bold */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Bold/Roboto-Bold.woff2?v=2.137") format("woff2"), url("../fonts/Bold/Roboto-Bold.woff?v=2.137") format("woff");
  font-weight: 700;
  font-style: normal; }
@font-face {
  font-family: Roboto;
  src: url("../fonts/Bold/Roboto-Bold.woff2?v=2.137") format("woff2"), url("../fonts/Bold/Roboto-Bold.woff?v=2.137") format("woff");
  font-weight: bold;
  font-style: normal; }
/* BEGIN Bold Italic */
@font-face {
  font-family: Roboto;
  src: url("../fonts/BoldItalic/Roboto-BoldItalic.woff2?v=2.137") format("woff2"), url("../fonts/BoldItalic/Roboto-BoldItalic.woff?v=2.137") format("woff");
  font-weight: 700;
  font-style: italic; }
@font-face {
  font-family: Roboto;
  src: url("../fonts/BoldItalic/Roboto-BoldItalic.woff2?v=2.137") format("woff2"), url("../fonts/BoldItalic/Roboto-BoldItalic.woff?v=2.137") format("woff");
  font-weight: bold;
  font-style: italic; }
/* BEGIN Black */
@font-face {
  font-family: Roboto;
  src: url("../fonts/Black/Roboto-Black.woff2?v=2.137") format("woff2"), url("../fonts/Black/Roboto-Black.woff?v=2.137") format("woff");
  font-weight: 900;
  font-style: normal; }
/* BEGIN Black Italic */
@font-face {
  font-family: Roboto;
  src: url("../fonts/BlackItalic/Roboto-BlackItalic.woff2?v=2.137") format("woff2"), url("../fonts/BlackItalic/Roboto-BlackItalic.woff?v=2.137") format("woff");
  font-weight: 900;
  font-style: italic; }

/* Kaushan regular */
@font-face {
  font-family: 'kaushan';
  src: url('../fonts/kaushanscript-regular-webfont.woff2') format('woff2'),
  url('../fonts/kaushanscript-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal; }